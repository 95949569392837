html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

  .H-content {
    flex: 1;
    z-index: 1; /* Ensure the content is above the background image */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white; /* White text for readability on the image */
    position: absolute;
    top: 10%;
    left: 20%;
    transform: translateX(-50%); /* Center the content horizontally */
}
 
  .H-paragraph, .H-nav-heading, .H-paragraph1{
    width: 100%;
    max-width: 100%; 
    margin: 10px 60px;
    text-align: left;
    align-items: left;
  }
  .H-paragraph, .H-paragraph1{
    color: #fff;
font-size: 15px;
font-family: Poppins;
font-weight: 400;

  }
  .H-paragraph1{
font-size: 18px;
white-space: nowrap;
position: relative;
left:10px

  }
  
  .H-nav-heading {
    font-size: 4em;
    color: #FFFAFA;
    font-size: 80px;
font-family: Poppins;
font-weight: 700;
line-height: 83px;
white-space: nowrap;
  }
/* Container for the dropdown */
.dropdown {
  width: 100%;
   height: 100%;
    border-radius: 6px;
     border: 1px rgba(255, 255, 255, 0.29);
     position:relative;
     left: 70px;
}

.btn-secondary.dropdown-toggle {
  background-color: transparent; /* Transparent background */
  border: 1px solid #ccc; /* Border color */
  padding: 8px 16px; /* Padding inside the button */
  width: 500px; /* Set your desired width */
  height: 50px; /* Set your desired height */
  border-radius: 4px; /* Rounded corners */
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color:rgba(255, 255, 255, 0.80);
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-;
transition: color 0.3s ease; /* Smooth transition for color change */
}
.btn-secondary.dropdown-toggle:hover {
  color: #096BD9; /* Primary color on hover */
  background-color: #ffffff;
}

.btn-secondary.dropdown-toggle::after {
  margin-left: 8px;
  font-size: 25px;
}


.dropdown-menu {
  border-radius: 15px;
  border: 2px solid #ccc; /* Border color for dropdown menu */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Dropdown shadow */
  width: 15vw; /* Ensure the menu matches button width */
}
.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Hover background color */
}
.Home1-grid{
margin-top: -140px;
}
.H-main-container1, .Home-grid {
  max-width: 2000px;
  width: 100%;
  margin: 0 auto; /* Center container */
  padding: 0 0; /* Add some padding to ensure it doesn't touch edges */
}

.Home-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 370px;
  padding-right: 470px;
  margin-left: 100px;
  margin-top: -120px;
  gap: 20px;
  margin-bottom: 70px;
  justify-content: center;
}

.H-icon-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 340px;
  height: 225px;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #e9e4e4;
  border-radius: 8px;
  background-color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
}

.H-icon {
  position: relative; /* Positions the icon relative to the container */
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.H-heading {
  font-size: 20px;
  margin: 0;
  padding: 0; 
  text-align: left;
  color: black;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word

}
.H-icon-container:hover .H-heading {
  color: #fff; /* Change text color to white on hover */
}
.H-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.60);
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  text-align: left;
  word-wrap: break-word;
 
}
.H-icon-container:hover {
  background-color: #3498db; /* Primary color on hover */
  border-color: #2980b9; /* Darker shade of primary color */
  transform: scale(1.05); /* Slightly increase the size */
}
.H-icon-container:hover .H-text {
  color: #fff; /* Change text color to white on hover */
}


  .H-primary-button {
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1em;
    margin-left: 320px;
    width: 250px;
    /* max-width: 12vw; */
     height: 100%;
     transition: background-color 0.3s ease; /* Smooth transition */
      background: linear-gradient(270deg, #096BD9 0%, #4896FF 100%);
       border-radius: 6px;
     border: 2px #6EACFB;
     margin-top: 10px;
  }
  
  .H-primary-button:hover {
    background: linear-gradient(270deg, #e1e4e8 0%, #eef0f3 100%); /* Change gradient on hover */
    animation: expand-shrink-jump 0.6s ease forwards; /* Apply animation */
  color: #096BD9;
  border: #096BD9 2px solid;
  }
    /* Keyframe animation for expanding, shrinking, and jumping */
    @keyframes expand-shrink-jump {
      0% {
          transform: scale(1); /* Initial size */
      }
      30% {
          transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
      }
      50% {
          transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
      }
      80% {
          transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
      }
      100% {
          transform: scale(1); /* Return to normal size */
      }
    }
  
  .H-heading2{
    margin-top: 130px;
    font-size: 48px;
font-family: Poppins;
font-weight: 700;
line-height: 83px;
word-wrap: break-word;
  }
  .H-micro-image{
  max-width: 65%; /* Adjust the width of the image */
  height: auto;
  position: absolute;
  top: 7%; /* Adjust this value as needed */
  left: 60vw;;
  }
  .container12, .main-container1, .image-container1 {
    position: relative; /* Ensure parent containers allow free positioning */
    overflow: visible; /* Ensure content is not clipped */
}
  
 
  .head-image{
    background: transparent;
    width: 100%;
    height: auto;
  }
  .image-container1{
    flex: 1;
    z-index: 1; /* Ensure this container is above the background image */
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .my-image {
    width: 100%; /* Make the image fill the container */
    max-width: 100%; /* Keep the image size within a specific limit */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 10px; /* Apply a border radius */
    position: absolute;
    right: 290px;
    top: 20px; /* Adjust positioning as needed */
    /* Add more styles as needed */
  }
   .main-container1 {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the heading, grid, and button horizontally */
    justify-content: flex-start;
    padding: 0;
    margin-top: 0;
  padding-top: 0;


  } 
  .heading2 {
    text-align: center; /* Center the heading text */
    margin-bottom: 10px; /* Add space between heading and containers */
  }
  
  .heading-part1 {
    color: #070707; /* First color */
    font-weight: 700;
    position: relative;
    top: 30px;
  }
  
  .heading-part2 {
    color: #2a4ef0; /* Second color */
    font-weight: 700;
    position: relative;
    top: 30px;
  }
 
  /* Add animation to the buttons */
.Btn-custom, .Btn-custom1 {
  transition: all 0.3s ease;
  margin-left: 200px;
  margin-bottom: 60px;
  margin-top: -45px;

  /* Any additional styling you want for the buttons */
}

/* Hover effect with keyframes */
.animated-btn:hover {
  animation: expand-shrink-jump 0.6s ease forwards;
}
.Btn-custom1:hover {
  background: linear-gradient(270deg, #0b6cea 0%, #4f84d3 100%); /* Change gradient on hover */
  animation: expand-shrink-jump 0.6s ease forwards; /* Apply animation */
color: #ffffff;
border: #ffffff 2px solid;
}
/* Keyframe animation for expanding, shrinking, and jumping */
@keyframes expand-shrink-jump {
  0% {
    transform: scale(1); /* Initial size */
  }
  30% {
    transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
  }
  50% {
    transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
  }
  80% {
    transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

  .center-page1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Btn-custom1{
    margin-left: -40px;
    position: absolute;
    margin-top: 50px;
  }
  .Home-image-container2 {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.Home-bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Ensures the background image stays behind other content */
}
.Home-overlay-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1400px;
  height: 100%;
  padding: 50px;
  margin-left: 10px;
}

.Home-doctor-image {
  width: 50%;
    /* max-width: 2000px; */
    height: 73vh;
    margin-bottom: -50px;
    /* margin-left: -30px; ; */
}

.HDM-heading-container {
  width: 50%;
  text-align: left;
  margin-top: -380px;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis; /* Adds "..." if the text is too long */
}

.HDM-heading3 {
  font-size: 3vw;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

.heading-part11 {
    color: #070707;
}

.heading-part223 {
    color: #2a4ef0;
    white-space: nowrap;
 /* margin-left: -440px; */
}

.HDM-content-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  width: 100%;
}

.HDM-ellipse-line-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: -630px;
    margin-top: 120px;
}

.small-line {
    height: 270px;
    stroke: #076AFF;
    stroke-width: 3px;
position: absolute;
top: -30px;

}

.ellipse {
    width: 25px;
    height: 25px;
    margin: 12px 0px;
    background-color: #076AFF;
    border-radius: 50%;
    position: relative;
    top: -20px;
    
}

.HDM-text-container {
  width: 50%;
  margin-left:-600px; 
  text-align: left;
  margin-top: 76px;
  /* gap: 20px; */
  /* padding-left: 0px; */
  
}

.bullet-point {
  font-size: 1.2vw;
  font-family: Poppins, sans-serif;
  margin: 21px 0;
  color: rgba(0, 0, 0, 0.7);
}
  .H-gradient-container {
    position: relative;
    width: 100vw;
    /* max-width: 100%; */
    height: 600px; /* Adjust this to the size you need */
    background: linear-gradient(to right, rgba(0, 47, 108, 1), rgba(8, 89, 149, 0.8), rgba(3, 92, 181, 0.804), rgb(42, 126, 236));
    display: flex;
    overflow: hidden;
    margin-top: 0px;
    margin-left: 60px;
  }
  .H-gradient-container img.H-background-image {
    position: absolute;
    top: 0;
    left: 0;
    /* max-width: 100%; */
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .H-gradient-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: 1;
  }
  .H-container-wrapper {
    position: relative;
    width: 100%;
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;
    text-align: center;
    left: 5vw;
    margin-top: 0;
    z-index: 2; /* Ensure it's above the background image */
  }

  .H-container-heading {
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
    position: relative;
right: 7vw;
    top: 60px;
    text-align: left;
  }
  .H-container-with-text1, .H-container-with-text2 {
    position: relative;
    margin: 20px;
    width: 45%; /* Adjust width so two containers fit in one row */
    height: 300px; /* Adjust height to your preference */
    background-size: cover;
    background-position: center;
    display: flex;
    border-radius: 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    overflow: hidden;
    color: black;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  .H-container-with-text1 {
    top: 87px;
    left: -155px;
  }
  .H-container-with-text2 {
    left: 450px;
    bottom: 235px;
  }
  
  .H-text-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .H-text-upper {
    background-color: white;
    padding: 30px;
    flex: 2; /* Increase the size of the upper portion */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: black;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  
  .H-text-lower {
    background-color: #d9d9d9;
    padding: 20px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 700;
    font-family: poppins;
    font-size: 22px;
  }
  .H-profile-image {
    margin-left: -200px;
  }
   .H-profile-image1 {
    margin-left: -260px;
  }
  .H-Paragraph12{
  margin-left: 20px;
  margin-top: 10px;

  }
  
  
  .container-row {
    display: flex;
    justify-content: space-between; /* Space between two containers */
    flex-wrap: wrap; /* Ensure responsiveness on smaller screens */
  }
  .main-container2 {
    width: 100vw;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    margin-bottom: 0px; /* Adds space below the container */
    padding-bottom: 90px; /* Adds space inside the bottom of the container */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  .center-content {
    text-align: center;
    margin-bottom: 20px;

  }
  .heading22{
    font-size: 48px;
    font-family: Poppins;
font-weight: 700;
line-height: 83px;
word-wrap: break-word
  }
  
  
  .description {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.60);
    margin-top: 40px;
    font-family: Poppins;
    word-wrap: break-word;
  }
  
  .logo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* gap: 20px; */
    width: 90vw;
    align-items: center;
  }
  
  .logo-item {
    width: 100%; /* Adjust the size of the boxes */
    height: 100px;
    background: rgba(217, 217, 217, 0);
    border: 2px solid #EFF1F3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  @media (min-width: 1921px) and (max-width: 2565px) {
    .container-12{
      height:45vh ;
    }
    .H-heading2{
      margin-top: -10px;
    }
  .Home-image-container2 {
margin-top: 300px;
width: 100vw;
margin-bottom: 350px;
overflow: visible;
left: -36px;
height: 40vh;
max-width: 100vw;
overflow: visible;
margin-bottom: 310px;
  }
  .center-page1 {
    margin-top: -50px;
  }
  .show-less-btn {
    margin-left: 08vw;
  }
  .Home-bg-image{
height: 52%;
width: 120%;
left: -240px;
margin-top: 200px;
overflow: visible;

  }
    .Home-overlay-content{
      height: 50vh;
   margin-right: 770px;
    } 
    .HDM-heading3{
      margin-top: -60px;
      margin-left: 0px;
      overflow: visible;
    }
   
    .small-line {
      height: 405px;
      top: -25px;
      stroke-width: 5px;
  
    }
    .ellipse {
      width: 30px;
      height: 30px;
      margin: 18.50px 0px;
    }
    .HDM-content-container{
      margin-top: 70px;
    }
    .Home-doctor-image{
      margin-top: -75px;
      width: 70%;
      height: 100vh;
    }
    
.Home-grid-container {
  margin-left: 177px; ;
  padding-right: 547px;
}
    .H-gradient-container {
      width: 100vw;
      left: -310px;
    
      margin-top: 40px;

    }
    .H-background-image{
width: 120%;
    }
    .H-gradient-container::after{
      width: 100vw;
      left: -30px;
      overflow: visible;
    }
    .H-container-wrapper {
      left: 300px;
    }
    .main-container2 {
      width: 128%;
      margin-left: -280px;
      overflow: visible;
    }
    
  }
  @media (min-width: 1410px) and (max-width: 1920px)
  
  {
    html, body {
      margin: 0;
      padding: 0;
      overflow-x: hidden;
    }
    .H-micro-image{
      max-width: 62%; 
      top: 1%; 
      left: 65vw;;
      }
    .main-container2,  .Home-grid {
      max-width: 100vw;
    
    }
    .H-main-container1{
      margin-top: -150px;
    }
    .H-gradient-container{
      max-width: 100vw;
      left: -60px;
      overflow: visible;
    }
    .Home-image-container2 {
      max-width: 100vw;
      height: 80vh;
   margin-bottom: 0px;
  
    }
    .H-container-wrapper {
      left: 160px;
    }
    /* .HDM-heading3{
      margin-left: 500px;
      margin-top: -380px;
    } */
    /* .HDM-content-container{
      margin-left: -40px;
      margin-top: 70px;
    } */
    /* .Home-doctor-image{ */
      /* top: 10px;
      left: 200px; */
      /* margin-top: -55px;
      margin-left: -70px;
      width: 40%;
    } */
   
  }
 