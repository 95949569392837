.MC-content {
    flex: 1;
    z-index: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    position: absolute;
    top: 22%;
    left: 20%;
    transform: translateX(-50%);
  }
  
  .MC-paragraph1, .MC-nav-heading {
    width: 100%;
    max-width: 490px;
    text-align: left;
    margin-top: -10px;
  }
  
  .MC-nav-heading {
    color: #FFFAFA;
    font-size: 66px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 83px;
    word-wrap: break-word;
  }
  
  .MC-paragraph1 {
    color: #FEFEFF;
    margin-top: 30px;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  .MC-text-container1 {
    text-align: left;
    margin-left: 10vw;
    margin-top: 7vh;
  }
  .MC-text-container2 {
    max-width: 60%;
    text-align: left;
margin-left: 11vw;
  }
 
  .MC-text-container13 {
    max-width: 60%;
    text-align: left;
    margin-left: auto;
    position: relative;
    left: -450px;
    top: -90px;
  }
  
  .MC-heading5 {
 
    text-align: left;
    font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;


  }
  
  .MC-heading-part111 {
    color: #070707;
    font-weight: 700;
  }
  
  .MC-heading-part222 {
    color: #2a4ef0;
    font-weight: 700;
  }
  
  .MC-paragraph21 {
    color:     rgba(0, 0, 0, 0.90);;
    font-weight: 400;
    line-height: 1.5;
    margin: 35px 0;
font-size: 18px;
font-family: Poppins;
word-wrap: break-word;
  }
/*   
  .MC-learn-more-container {
    display: flex;
    align-items: center;
    margin-top: -20px;
  }
  
  .MC-learn-more-text {
    color: #007bff;
    cursor: pointer;
    font-size: 20px;
font-family: Anek Telugu;
font-weight: 600;
word-wrap: break-word
  }
  
  .MC-arrow {
    font-size: 20px;
    margin-left: 10px;
    color: #007bff;
  } */

  .MC-dp-image14 {
    width: 28vw;
    height: 48vh;
    position: relative;
/* margin-left: 52vw; */
left: 50vw;
top: -45vh;
/* bottom: 51vh; */
  }
  
  .MC-dp-image1 {
    width: 28vw;
    height: auto;
    margin-left: 47vw;
    margin-top: -45vh;
    margin-bottom: 40vh;
    /* position: relative;
    left: 810px;
    bottom: 380px; */
  }
  .MC-dp-image13 {
    width: 28vw;
    height: auto;
    position: relative;
    left: 800px;
    bottom: 270px;
  }
  .MC-paragraph2011 {
    margin-top: 6vh;
    color: white;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
/*   
  .MC-learn-more-container11 {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .MC-learn-more-text11, .MC-arrow11 {
    color: white;
    font-size: 18px;
    /* margin-right: 10px; */
  /* } */
  .MC-image-container2 {
    display: flex;
    position: relative;
    width: 100%;
    bottom: 30vh;
    height: 70vh;
    overflow: hidden;
  
  }
  
  .MC-my-image1 {
    position: absolute;
    top: 3px;
    left: 0px;
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    /* margin-top: 0px; */
    border-radius: 8px;
  }
  
  .MC-overlay-content1 {
    position: relative;
    /* display: flex; */
    /* justify-content: space-between; */
    padding: 20px;
    z-index: 2;
     margin-left: 20vw; 
     margin-top: -18vh;
/* top: 30px;  */
display: flex;
flex-direction: column; /* Stack elements vertically */
align-items: flex-start; /* Align content to the left */
justify-content: center;
  }
  .MC-overlay-content2 {
    position: relative;
    /* display: flex; */
    /* justify-content: space-between; */
    padding: 20px;
    z-index: 2;
     margin-left: 10vw; 
     margin-top: -18vh;
/* top: 30px;  */
display: flex;
flex-direction: column; /* Stack elements vertically */
align-items: flex-start; /* Align content to the left */
justify-content: center;
  }
  
  .MC-heading4 {
    font-size: 48px;
    font-weight: 700;
    line-height: 55px;
    margin-top: 9vh;
    font-family: Outfit;
/* margin-left: 80px; */
white-space: nowrap;
display: flex; 
flex-wrap: wrap; /* Ensure text wraps properly */
align-items: baseline; /* Aligns heading parts properly */
  }
  
  .MC-paragraph201 {
    font-size: 18px;
    text-align: left;
    /* margin-top: 17vh; */
    font-weight: 400;
    margin-left: 0; /* Ensure paragraph starts at the same left position */
  }
  
  .MC-dp-image {
    width: 28vw;
    height: 50vh;
    position: relative;
    left: 10vw;
    top: 10vh;
  }
  /* .MC-learn-more-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
   */
/*   
  .MC-learn-more-text12{
    color: #2a4ef0;
    font-size: 18px;
    margin-right: 20px;
    position: relative;
    right: 965px;
    top: 165px; */

    /* left: 80px; */
   
  /* }
  .MC-arrow12 {
    color: #2a4ef0;
    font-size: 20px;
    margin-right: 10px;
    position: relative;
    right: 975px;
    top: 165px;
  
  } */
  .MC-gradient-container {
    position: relative;
    width: 100%;
    height: 70vh; /* Adjust this to the size you need */
    background: linear-gradient(to right, rgba(0, 47, 108, 1), rgba(8, 89, 149, 0.8), rgba(3, 92, 181, 0.804), rgb(42, 126, 236));
    display: flex;
    align-items: center;
    overflow: hidden;
    bottom:15vh;
    margin-top: -120px;
    margin-bottom: 30px;
  }
  .MC-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.3; /* Reduce the shine of the image */
  }
  .MC-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(18, 59, 112, 0.5); /* Slight blue overlay */
    z-index: 1;
  }
  .MC-content-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    z-index: 2;
    margin-left: 9vw;
  }
  
  .MC-text-content {
    color: white;
    max-width: 50%; /* Adjust according to your layout needs */
    text-align: left;
    margin-right: 12vw;
    /* position: relative;
    rifgt ;
    top: -40px; */
  }
  
  .MC-heading7 {
    font-size: 48px;
    font-weight: 700;
    line-height: 55px;
    margin-top: 9vh;
    font-family: Outfit;
margin-left: 80px;
  }
  .MC-heading-part11112{
 
  }
  .MC-paragraph2011 {
    font-size: 18px;
    margin-top: 6vh;
    font-weight: 400;
    margin-left: 80px;
  }
  
/*  
  .MC-learn-more-container1 {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    font-size: 16px;
font-family: Anek Telugu;
font-weight: 600;
    left:20px;
    white-space: nowrap;
  
  }
  
  .MC-learn-more-text17{
    color: #FFFAFA;
    font-size: 18px;
    margin-right: 20px;
    position: relative;
    left: 80px;
   
  }
  .MC-arrow17 {
    color: #FFFAFA;
    font-size: 20px;
    margin-right: 10px;
    position: relative;
    left: 70px;
  
  } */
  
  .MC-dp-image2 {
    width: 28vw;
    height: 50vh;
    position: relative;
    left: 10px;
    /* top: vh; */
  }
  .MC-heading452{
    /* margin-bottom: 10px; */
    text-align: left;
    margin-top: -28vh;
    /* position: relative;
    bottom: 18vh;
   right: 22vw; */
   white-space: nowrap;
   font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
  }
  .three-containers-row {
    display: flex;
    justify-content: space-between; /* Space containers evenly */
    align-items: center; /* Center content vertically if containers have different heights */
    width: 100%;
    gap: 20px; /* Adjust gap between containers */
    padding: -40px; /* Optional: adjust padding for the row */
margin-left: -25vw;
margin-right: 22vw;
  }
  
  .white-container {
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    width: 33%;
    height: 49%;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 2; /* Ensure containers are above the image overlay */
  }
  
  .container-heading {
    margin-bottom: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add text shadow */
    color: black;
font-size: 20px;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word
  }
  
  .container-paragraph {
    font-size: 16px;
    color:  rgba(0, 0, 0, 0.60);
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  .MC-heading455{
    line-height: 55px;
   position: relative;
   left: 1vw;
  bottom: 220px;
  font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
  }
  
  .MC-dp-image11 {
     position: relative; 
    right: -48vw; /* Align image to the right */
    top: 50%; /* Centers vertically */
    transform: translateY(-50%); /* Adjusts for centering */
    width: 28vw;
    height: auto;
    margin-top: -60vh;
    /* margin-left: 55vw;
    margin-top: -140vh; */
    /* position: relative;
    left: 55vw;
    top: -98vh; */
   /* margin-left: 50vw;
   margin-top: -100vh; */
}
  .left-side-content {
    width: 90%; /* Adjust width as needed */
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    bottom: 15vh;
    
  }
  
  .content-block {
    margin-bottom: 20px;
  }
  
  .content-heading {
    margin-bottom: 10px;
    color: black;
font-size: 20px;
font-family: Poppins;
font-weight: 700;
  }
  
  .content-paragraph {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.60);
    margin-bottom: 20px;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  /* Keyframes for the expand-shrink-jump animation */
@keyframes expand-shrink-jump {
  0% {
      transform: scale(1); /* Initial size */
  }
  30% {
      transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
  }
  50% {
      transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
  }
  80% {
      transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
  }
  100% {
      transform: scale(1); /* Return to normal size */
  }
}
  .primary-button {
    background-color:#ffffff ; /* Primary blue color */
    color: #007bff;
    padding: 10px 20px;
    font-size: 16px;
    width: 170px;
    border: 2px solid #007bff;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease-in-out; /* Smooth transition */
    animation: none; /* Initially no animation */
  }
  .primary-button:hover {
    animation: expand-shrink-jump 0.6s ease-in-out; /* Apply the animation when hovered */
}

.primary-button:active {
    animation: expand-shrink-jump 0.6s ease-in-out; /* Apply the animation when clicked */
}
  .primary-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    color: #EAF1FD;
  }
  .MC-heading4441{
    font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
  }
  .outer-container {
    background-color: #EAF1FD;
    width: 100%;
    height: 60vh;
    padding: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: left;
    
  }
  
  .inner-container {
    display: flex;
    gap: 20px; /* Space between content-boxes */
    width: 85%; /* Adjust width as needed */
    justify-content: space-between;
    height: 170px;
margin: 80px;
  }
  
  .content-box {
  
    /* width: 10.50vw;  */
    /* padding: 15px; */
    /* text-align: center; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    position: relative; /* Ensures absolute positioning works */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
  }
  .step-box {
    background-color: #F2F2F2;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 200px; /* Adjust width as needed */
    position: relative;
    height: auto;
    z-index: 1;
  }
  .step-box {
    flex: 1; /* Ensures the text section takes full width */
  }
  
  .arrow-image1,
  .arrow-image2,
  .arrow-image3,
  .arrow-image4,
  .arrow-image5 {
    position: absolute;
    right: -50px; /* Adjust for perfect positioning */
    top: 50%;
    transform: translateY(-50%); /* Centers the arrow vertically */

  }
  .step-box.with-rounded-border::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 2px dashed #101010; /* Use dashed border to mimic the rounded dashed SVG */
    border-radius: 14px;
    z-index: -1;
  }
  .content-heading1 {
    font-size: 18px;
    color: black;
    margin-bottom: 10px;
    font-family: Poppins;
font-weight: 700;
word-wrap: break-word
  }
  
  .content-paragraph1 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    word-wrap: break-word
  }
  .arrow-image1 {
    width: 80px;
    height: 158px;
margin-right: 1.53vw;
    /* margin-left: -7vw; */
    /* margin-top: -22vh;
    margin-left: 10.6vw;  */
    /* position: relative;
    bottom:147px ;
    left: 105px; */
   }
  .arrow-image2 {
    width: 80px; 
    height: 160px;
    margin-right: 1.50vw;
    /* position: relative;
    bottom:173px ;
    left: 103px; */
  }
  .arrow-image3 {
    width: 80px; 
    height: 160px;
    margin-right: 1.50vw;
    /* position: relative;
    bottom:147px ;
    left: 103px; */
  } 
  .arrow-image4 {
    width: 80px; 
    height: 160px;
    margin-right: 1.50vw;
    /* position: relative;
    bottom:172px ;
    left: 103px; */
  }
  .arrow-image5 {
    width: 180px; 
    height: 160px;
    margin-right: 2.50vw;
  } 
  @media (min-width: 1921px) and (max-width: 2565px) {
    .MC-text-container1 {
    left: -490px;
    margin-top: 100px;
    }
    .MC-heading5 {
      /* margin-top: 70px; */
    }
    .MC-dp-image14 {
    top: -490px;
    left: 1000px;
    }
    .MC-dp-image {
      height: 35vh;
      width: 26vw;
      right: 330px;
    }
    .MC-dp-image13 {
      bottom: 420px;
    }
    .MC-gradient-container {
      height: 50vh;
    }
    .MC-paragraph201 {
      top: 100px;
    }
    .MC-image-container2 {
      height: 50vh;
      margin-top: 100px;
    }
    .MC-overlay-content1 {
      left: 70vw;
    }
    .MC-heading4 {
      left: -190px;
    }
    .MC-dp-image2 {
      right:1280px;
      height: 38vh;
    }  
.MC-text-content {
left: 53vw;
}
.MC-heading-part11112 {
  right: 5.5vh;
  top: 9vh;
}
.MC-heading452 {
  right: 31vw;
  top: -150px;
}
.MC-heading455 {
  left: -160px;
  top: -270px;
}
.left-side-content {
  left: -170px;
}
.MC-dp-image11 {
  top: -310px;
  left: 1100px;
}
.MC-dp-image1 {
  margin-top: -50px;
}
.content-box {
  width: 10%;
}
.inner-container {
  padding-left: 100px;
  padding-right: 200px;
  left: 10vw;
/* margin: 0 100px; */
  /* margin-right: 15vw; */
}
.step-box {
  width: 300px;
  height: 200px;
}
.arrow-image1 {
  width: 90px;
    height: 400px;
  left: 230px;
  bottom: 300px;
}
.arrow-image2 {
  width: 90px;
    height: 400px;
  left: 230px;
  bottom: 300px;
}
.arrow-image3 {
  width: 90px;
    height: 400px;
  left: 230px;
  bottom: 300px;
}
.arrow-image4 {
  width: 90px;
    height: 400px;
  left: 230px;
  bottom: 300px;
}
.arrow-image5 {
  width: 235px;
    height: 400px;
  left: 81px;
  bottom: 300px;
}
.outer-container {
  height: 50vh;
}
.content-paragraph {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
  }
   @media (min-width: 1410px) and (max-width: 1910px) {
    .MC-dp-image14 {
      top: -53vh;
    }
    .MC-dp-image11{
      right: -48vw;
      margin-top: -80vh;
    } 
    /* .MC-text-container1 {
      top: -60px;
      margin-bottom: -60px;
    }

    .MC-image-container2 {
      height: 55vh;
      margin-bottom: 50px;
    }
    .MC-dp-image{
      margin-left: 160px;
      margin-top: -30px;
      height: 35vh;
      width: 30vw;
    }
    .MC-gradient-container {
      height: 55vh;
    }
    .MC-overlay-content1{
      margin-left: -280px;
    }
    .MC-heading4{
     margin-right: -100px;
    }
    .MC-text-container13{
      margin-top: 80px;
      margin-bottom: -150px;
    }
    .MC-dp-image13{
      margin-left: -120px;
      width: 30vw;
    height: auto;
    }
    .MC-text-content{
      margin-left: -120px;
    }
    .MC-dp-image2{
      margin-right: -170px;
      width: 30vw;
      height: 38vh;
    }
    .MC-text-container2{
      margin-left:540px ;
    }
    .MC-heading-part11112 {
      right: 8.5vh;
    }
    .MC-dp-image1{
      margin-left: -150px;
      width: 30vw;
    }
    .MC-heading452 {
      top: -100px;
      left: -390px;
    }
    .three-containers-row{
      margin-left: 220px;
    }
    .MC-heading455{
      margin-top: 50px;
    }
   
    /* .outer-container {
      margin-top: -150px;
      margin-bottom: -50px;
    }
    .content-box {
      width: 16%;
      height: 148px;    
    } */
    .arrow-image1 {
      margin-right: 1.69vw;
    }
    .arrow-image2 {
      height: 183px;
      margin-right: 1.80vw;
    }
    .arrow-image3 {
      margin-right: 1.65vw;
    }
    .arrow-image4 {
      height: 180px;
      margin-right: 1.72vw;
    }
    .arrow-image5 {
      height: 180px;
      margin-right: 3.55vw;
    }
  } 